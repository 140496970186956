import React, { Component, FocusEventHandler, FormEvent, useState } from 'react';
import { ArmyEntry, defaultConfig, defaultTownHall, randomizeArmy, RngConfig } from './rng.js';
import { townHalls, Unit } from './troops.js';

export const getUnitImage = (unit: Unit) => `img/${unit.kind}s/${String(unit.id).padStart(2, '0')}-${unit.name.toLowerCase().replace(/[^\w.]+/g, '-')}.png`;
export const getArmyLink = (units: ArmyEntry[]) => {
	const troops = [
		...units.filter(e => e.unit.kind == 'troop'),
		...units.filter(e => e.unit.kind == 'siege'),
	].map(e => `${e.count}x${e.unit.id}`);
	const spells = units.filter(e => e.unit.kind == 'spell').map(e => `${e.count}x${e.unit.id}`);
	const str = `u${troops.join('-')}${spells.length ? `s${spells.join('-')}` : ''}`;
	return `https://link.clashofclans.com/en?action=CopyArmy&army=${str}`;
};

export interface ArmiesEntry {
	army: ArmyEntry[];
	total: number;
	link: string;
}

export type RngUiScreen = 'config' | 'result';
interface RngUiState {
	army?: ArmyEntry[];
	armies?: ArmiesEntry[];
	total?: number;
	link?: string;
	config: RngConfig;
	screen: RngUiScreen;
}

export class RngUi extends Component<{}, RngUiState> {

	constructor(props: {}, context: any) {
		super(props, context);
		this.state = {
			...this.state,
			config: this.restoreConfig(),
			screen: 'config',
			armies: [],
		};
	}
	
	restoreConfig = () => {
		if (typeof location != 'object' || location.hash.length < 2) return defaultConfig();
		try {
			const usp = new URLSearchParams(location.hash.slice(1));
			const saved = Object.fromEntries([...usp.entries()].map(([k, v]) => [k, JSON.parse(v)])) as Partial<RngConfig>;
			const th = saved.townHall && townHalls.find(th => th.level == saved.townHall) || defaultTownHall();
			return {
				...defaultConfig(th),
				...saved,
			};
		}
		catch (e) {
			console.error(e);
			return defaultConfig();
		}
	};
	
	updateConfig = (config: RngConfig) => {
		this.setState({ config });
		const def = defaultConfig(townHalls.find(th => th.level == config.townHall));
		const sp = new URLSearchParams(
			Object.entries(config)
				.filter(([k, v]) => k == 'townHall' ? v != defaultTownHall().level : v != def[k as keyof RngConfig])
				.map(([k, v]) => [k, JSON.stringify(v)])
		);
		history.replaceState(null, '', `#${sp}`);
	};

	render() {
		const { config, screen, armies } = this.state;
		return <div>
			<div className="oddities">
				<Header/>
				{screen == 'config' && <ConfigEditor
					config={config}
					update={this.updateConfig}
					done={this.rerollAndShow}
				/>}
				{screen == 'result' && <ArmyDisplay
					armies={armies ?? []}
					navigate={(screen) => this.setState({ ...this.state, screen })}
					reroll={this.reroll}
				/>}
			</div>
		</div>;

	}

	componentDidMount() {
		this.reroll();
	}

	reroll = () => {
		const { config } = this.state;

		const armies: ArmiesEntry[] = [];

		for (let i = 1; i <= config.spinCount; i++) {
			const army = randomizeArmy(config);
			const link = getArmyLink(army);
			const total = army.reduce((a, e) => a + e.total, 0);
			armies.push({ army, link, total });
		}

		this.setState({ armies });

		(window as any).gtag?.('event', 'reroll');
	};

	rerollAndShow = () => {
		this.reroll();
		this.setState({ screen: 'result' });
	};

}
function Header() {
	return <div className="oddities-navbar">
		<a href="https://oddities.gg" className="oddities-navbar__logo">
			<img src="img/logo.svg" className="oddities-navbar__img" alt="Oddities" />
		</a>
	</div>;
}

function ConfigEditor({ config, update, done }: {
	config: RngConfig;
	update: (config: RngConfig) => void;
	done: () => void;
}) {

	const townHall = townHalls.find(th => th.level == config.townHall) ?? defaultTownHall();
	const maxSpells = townHall.maxSpellCost ?? 0;
	const maxSiege = townHall.maxSiegeCost ?? 0;

	const selectAll: FocusEventHandler = e => (e.target as HTMLInputElement).select();

	const setTownHall = (level: number) => {
		const th = townHalls.find(th => th.level == level);
		if (!th || !townHall) throw new Error('What.');
		update({
			...config,
			townHall: th.level,
			maxTotalCost: config.maxTotalCost >= townHall.maxCost ? th.maxCost : config.maxTotalCost,
			maxCostPerTroop: config.maxCostPerTroop >= townHall.maxCost ? th.maxCost : config.maxCostPerTroop,
		});
	};

	const numberInput = (key: keyof RngConfig, label: string, max?: number) => <div className="oddities-form__group">
		<label className="oddities-form__label">{label}</label>
		<input
			className="oddities-form__input"
			type="number"
			placeholder={label}
			value={config[key]}
			onInput={(e: FormEvent) => {
				const target = (e.target as HTMLInputElement);
				if (!target.validity.valid) return;
				update({ ...config, [key]: Math.max(Math.min(target.valueAsNumber, max ?? Infinity), 0) });
			}}
			onFocus={selectAll}
		/>
	</div>;

	const numberToggle = (key: keyof RngConfig, label: string, value: number) => <label className="oddities-form__toggle">
		<p>{label}</p>
		<input
			type="checkbox"
			checked={config[key] > 0}
			onChange={() => update({ ...config, [key]: config[key] ? 0 : value })}
		/>
		<span className="oddities-form__checkmark"></span>
	</label>;

	return <div className="oddities-container">
		<form className="oddities-form" onSubmit={(e) => e.preventDefault()}>
			<div className="oddities-form__group">
				<select
					className="oddities-form__input oddities-form__input--select"
					value={config.townHall}
					onChange={e => setTownHall(Number(e.target.value))}
				>
					{townHalls.map(th => <option value={th.level}>Town Hall {th.level}</option>)}
				</select>
			</div>

			{numberInput('maxTotalCost', 'Army Size to Randomize')}
			{numberInput('minTroopTypes', 'Minimum Troop Types')}
			{numberInput('maxTroopTypes', 'Maximum Troop Types')}
			{numberInput('minCostPerTroop', 'Minimum Camp Space Per Troop')}
			{numberInput('maxCostPerTroop', 'Maximum Camp Space Per Troop')}

			{numberToggle('maxSuperTroops', 'Include Super Troops', 2)}
			{!!config.maxSuperTroops && numberInput('maxSuperTroops', 'Maximum Super Troop Types', 2)}

			{!!maxSpells && <>
				{numberToggle('maxSpellTypes', 'Include Spells', maxSpells)}
				{!!config.maxSpellTypes && <>
					{numberInput('maxSpellTypes', 'Maximum Spell Types', maxSpells)}
					{numberInput('maxSpellTotal', 'Maximum Spell Cost', maxSpells)}
				</>}
			</>}

			{!!maxSiege && numberToggle('maxSiegeTotal', 'Include Siege Machines', maxSiege)}

			{numberInput('spinCount', 'Spin Count')}

			<div className="oddities-form__group">
				<button
					type="submit"
					className="oddities-form__btn oddities-form__btn--active"
					onClick={done}
				>Create Your Army!</button>
			</div>
			<div className="oddities-form__group">
				<button
					type="button"
					className="oddities-form__btn"
					onClick={() => update(defaultConfig())}
				>Reset</button>
			</div>

		</form>
	</div>;

}

function ArmyDisplay({ armies, navigate, reroll }: {
	armies: ArmiesEntry[];
	navigate: (screen: RngUiScreen) => void;
	reroll: () => void;
}) {
	const [linkAll, copyLinkAll] = useState(`Copy ${armies.length} ${armies.length == 1 ? 'Army' : 'Armies'}`);
	const [linkText, copyLinkText] = useState('Copy Army Link');
	const [linkIndex, copyLinkIndex] = useState(0);
	const copyLink = (link: string, index: number) => {
		navigator.clipboard.writeText(link);
		copyLinkText('Copied');
		copyLinkIndex(index);
		setTimeout(() => {
			copyLinkText('Copy Army Link');
		}, 2000);
	};
	const copyAllLinks = () => {
		const allLinks = armies.map((e, i) => 'Army #'+(i+1) +'\r\n' + e.link).join('\r\n');
		navigator.clipboard.writeText(allLinks);
		copyLinkAll('Copied');
		setTimeout(() => {
			copyLinkAll(`Copy ${armies.length} ${armies.length == 1 ? 'Army' : 'Armies'}`);
		}, 2000);
	};
	return <div className="oddities-army">
		<div className="oddities-primary-actions">
			<h1 className="oddities-primary-actions__heading">{armies.length} {armies.length == 1 ? 'Army' : 'Armies'} Spun</h1>
			<div className="oddities-actions__row">
				<button className="oddities-actions__btn" onClick={copyAllLinks}>{linkAll}</button>
				<button className="oddities-actions__btn" onClick={() => navigate('config')}>Edit</button>
				<button className="oddities-actions__btn oddities-actions__btn--disabled" onClick={reroll}>Spin Again?</button>
			</div>
		</div>
		{armies.map((list, index) => <div className="oddities-army-wrapper">
			<div className="oddities-troops">
				<h2 className="oddities-troops__heading">Army #{index+1}</h2>
				<ul className="oddities-troops__list">
					{list.army.map( e => <li className="oddities-troops__item">
						<div className="oddities-troops__overlay">x{e.count}</div>
						<img className="oddities-troops__img" alt={e.unit.name} src={getUnitImage(e.unit)}/>
					</li>)}
				</ul>
			</div>
			<div className="oddities-actions">
				<div className="oddities-actions__row">
					<button className="oddities-actions__btn" onClick={() => copyLink(list.link, index)}>{linkIndex == index ? linkText : 'Copy Army Link'}</button>
					<a href={list.link} target="_blank" className="oddities-actions__btn oddities-actions--link">Open Army Link</a>
				</div>
			</div>
		</div>)}

	</div>;
}


import { SiegeMachine, Spell, townHalls, Troop, UnitKind, unitList, unitMap, UnitType } from './troops.js';

export interface ArmyEntry {
	unit: Troop | Spell | SiegeMachine;
	count: number;
	total: number;
}

// NOTE: The url hash save/restore assumes there are only numbers here. If it changes, might need fixing.
export interface RngConfig {

	townHall: number;

	maxTotalCost: number;
	minTroopTypes: number;
	maxTroopTypes: number;
	maxSuperTroops: number;
	minCostPerTroop: number;
	maxCostPerTroop: number;

	maxSpellTotal: number;
	maxSpellTypes: number;

	maxSiegeTotal: number;

	spinCount: number;

}

export const defaultTownHall = () => townHalls.find(th => th.default) ?? townHalls[0];
export const defaultConfig = (th = defaultTownHall()): RngConfig => ({

	townHall: th.level,

	maxTotalCost: th.maxCost,
	minTroopTypes: 1,
	maxTroopTypes: 10,
	maxSuperTroops: 0,
	minCostPerTroop: 0,
	maxCostPerTroop: th.maxCost,

	maxSpellTotal: th.maxSpellCost ?? 0,
	maxSpellTypes: th.maxSpellCost ?? 0,

	maxSiegeTotal: th.maxSiegeCost ?? 0,

	spinCount: 1,

});

const randomIndex = (() => {
	const max = Math.pow(2, 32);
	const buf = new Uint32Array(1);
	return (len: number) => {
		window.crypto.getRandomValues(buf);
		return Math.floor(buf[0] / max * len);
	};
})();

function randomizeUnits<T extends Extract<UnitType, { kind: K }>, K extends UnitKind>(params: {

	kind: K;

	townHall: number;

	maxTotal: number;
	minTypes: number;
	maxTypes: number;
	minCost: number;
	maxCost: number;

	maxSuperTroops: number;

 }) {

	const { kind, townHall, maxTotal, minTypes, maxTypes, minCost, maxCost, maxSuperTroops } = params;

	let left = maxTotal;
	let superTypesLeft = maxSuperTroops;
	const counts = new Map<number, number>();
	const types = new Set<number>();

	const validUnits = (unitList[kind] as T[]).filter(t => (t.townHall ?? 0) <= townHall);

	while (left) {

		let avail = validUnits.map(t => ({
			...t,
			curCost: (counts.get(t.id) ?? 0) * t.cost,
			minCost: types.has(t.id) ? t.cost : Math.max(t.cost, Math.ceil(minCost / t.cost) * t.cost),
		}));
		avail = avail.filter(t => t.minCost <= left && (!maxCost || t.curCost + t.minCost <= maxCost));
		if (types.size < minTypes) avail = avail.filter(t => !types.has(t.id));
		if (types.size >= maxTypes) avail = avail.filter(t => types.has(t.id));
		if (!superTypesLeft) avail = avail.filter(t => !t.super || types.has(t.id));
		if (!avail.length) break;

		const unit = avail[randomIndex(avail.length)];
		const tid = unit.id;

		const minQty = Math.round(unit.minCost / unit.cost);
		counts.set(tid, (counts.get(tid) ?? 0) + minQty);

		left -= unit.minCost;
		if (unit.super && !types.has(tid)) superTypesLeft--;

		types.add(tid);

	}

	return [...counts.entries()].map(([tid, count]) => ({
		unit: unitMap[kind][tid],
		count,
		total: count * unitMap[kind][tid].cost,
	}) as ArmyEntry);
	
}

export function randomizeArmy(config: RngConfig) {
	
	const { townHall, maxSuperTroops, maxTroopTypes, maxTotalCost, minCostPerTroop, minTroopTypes, maxCostPerTroop, maxSpellTypes, maxSpellTotal, maxSiegeTotal } = config;
	
	const troops = randomizeUnits({
		townHall,
		kind: 'troop',
		minTypes: minTroopTypes,
		maxTypes: maxTroopTypes,
		minCost: minCostPerTroop,
		maxCost: maxCostPerTroop,
		maxTotal: maxTotalCost,
		maxSuperTroops: maxSuperTroops,
	});
	const spells = randomizeUnits({
		townHall,
		kind: 'spell',
		minTypes: Math.min(maxSpellTypes, 1),
		maxTypes: maxSpellTypes,
		minCost: 0,
		maxCost: maxSpellTotal,
		maxTotal: maxSpellTotal,
		maxSuperTroops: 0,
	});
	const sieges = randomizeUnits({
		townHall,
		kind: 'siege',
		minTypes: Math.min(maxSiegeTotal, 1),
		maxTypes: maxSiegeTotal,
		minCost: 0,
		maxCost: maxSiegeTotal,
		maxTotal: maxSiegeTotal,
		maxSuperTroops: 0,
	});

	const kinds: UnitKind[] = ['troop', 'spell', 'siege'];
	const final = [...troops, ...spells, ...sieges];
	final.sort((a, b) => (
		kinds.indexOf(a.unit.kind) - kinds.indexOf(b.unit.kind)
		|| b.total - a.total
		|| b.count - a.count
		|| a.unit.id - b.unit.id
	));

	return final;
	
}

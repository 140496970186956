export interface TownHall {
	level: number;
	maxCost: number;
	default?: boolean;
	maxSpellCost?: number;
	maxSiegeCost?: number;
}

export const townHalls: TownHall[] = [
	{
		level: 2,
		maxCost: 30,
	},
	{
		level: 3,
		maxCost: 70,
	},
	{
		level: 4,
		maxCost: 80,
	},
	{
		level: 5,
		maxCost: 135,
		maxSpellCost: 2,
	},
	{
		level: 6,
		maxCost: 150,
		maxSpellCost: 4,
	},
	{
		level: 7,
		maxCost: 200,
		maxSpellCost: 6,
	},
	{
		level: 8,
		maxCost: 200,
		maxSpellCost: 7,
	},
	{
		level: 9,
		maxCost: 220,
		maxSpellCost: 9,
	},
	{
		level: 10,
		maxCost: 240,
		maxSpellCost: 11,
		maxSiegeCost: 1,
	},
	{
		level: 11,
		maxCost: 260,
		maxSpellCost: 11,
		maxSiegeCost: 1,
	},
	{
		level: 12,
		maxCost: 280,
		maxSpellCost: 11,
		maxSiegeCost: 1,
	},
	{
		level: 13,
		maxCost: 300,
		maxSpellCost: 11,
		maxSiegeCost: 1,
	},
	{
		level: 14,
		maxCost: 300,
		maxSpellCost: 11,
		maxSiegeCost: 1,
	},
	{
		level: 15,
		maxCost: 320,
		maxSpellCost: 11,
		maxSiegeCost: 1,
		default: true,
	},
];

export type UnitKind = 'troop' | 'spell' | 'siege';
export interface Unit {
	kind: UnitKind;
	id: number;
	name: string;
	townHall: number;
	cost: number;
	super?: boolean;
}
export interface Troop extends Unit {
	kind: 'troop';
	super: boolean;
}
export interface Spell extends Unit {
	kind: 'spell';
	super?: never;
}
export interface SiegeMachine extends Unit {
	kind: 'siege';
	super?: never;
}
export type UnitType = Troop | Spell | SiegeMachine;

const define = <K extends UnitKind, T extends Extract<UnitType, { kind: K }>> (kind: K, list: Omit<T, 'kind'>[]) => list.map(
	unit => ({ kind, ...unit } as T)
) as T[];

export const unitList = {

	troop: define('troop', [
		{
			name: 'Barbarian',
			id: 0,
			cost: 1,
			super: false,
			townHall: 2,
		},
		{
			name: 'Archer',
			id: 1,
			cost: 1,
			super: false,
			townHall: 2,
		},
		{
			name: 'Goblin',
			id: 2,
			cost: 1,
			super: false,
			townHall: 2,
		},
		{
			name: 'Giant',
			id: 3,
			cost: 5,
			super: false,
			townHall: 2,
		},
		{
			name: 'Wall Breaker',
			id: 4,
			cost: 2,
			super: false,
			townHall: 3,
		},
		{
			name: 'Balloon',
			id: 5,
			cost: 5,
			super: false,
			townHall: 4,
		},
		{
			name: 'Wizard',
			id: 6,
			cost: 4,
			super: false,
			townHall: 5,
		},
		{
			name: 'Healer',
			id: 7,
			cost: 14,
			super: false,
			townHall: 6,
		},
		{
			name: 'Dragon',
			id: 8,
			cost: 20,
			super: false,
			townHall: 7,
		},
		{
			name: 'Pekka',
			id: 9,
			cost: 25,
			super: false,
			townHall: 8,
		},
		{
			name: 'Minion',
			id: 10,
			cost: 2,
			super: false,
			townHall: 7,
		},
		{
			name: 'Hog Rider',
			id: 11,
			cost: 5,
			super: false,
			townHall: 7,
		},
		{
			name: 'Valkyrie',
			id: 12,
			cost: 8,
			super: false,
			townHall: 8,
		},
		{
			name: 'Golem',
			id: 13,
			cost: 30,
			super: false,
			townHall: 8,
		},
		{
			name: 'Witch',
			id: 15,
			cost: 12,
			super: false,
			townHall: 9,
		},
		{
			name: 'Lava Hound',
			id: 17,
			cost: 30,
			super: false,
			townHall: 9,
		},
		{
			name: 'Super Barbarian',
			id: 26,
			cost: 5,
			super: true,
			townHall: 11,
		},
		{
			name: 'Bowler',
			id: 22,
			cost: 6,
			super: false,
			townHall: 10,
		},
		{
			name: 'Baby Dragon',
			id: 23,
			cost: 10,
			super: false,
			townHall: 9,
		},
		{
			name: 'Miner',
			id: 24,
			cost: 6,
			super: false,
			townHall: 10,
		},
		{
			name: 'Super Giant',
			id: 29,
			cost: 10,
			super: true,
			townHall: 12,
		},
		{
			name: 'Super Archer',
			id: 27,
			cost: 12,
			super: true,
			townHall: 11,
		},
		{
			name: 'Super WB',
			id: 28,
			cost: 8,
			super: true,
			townHall: 11,
		},
		{
			name: 'Yeti',
			id: 53,
			cost: 18,
			super: false,
			townHall: 12,
		},
		{
			name: 'Super Goblin',
			id: 55,
			cost: 3,
			super: true,
			townHall: 11,
		},
		{
			name: 'Rocket Balloon',
			id: 57,
			cost: 8,
			super: true,
			townHall: 12,
		},
		{
			name: 'Ice Golem',
			id: 58,
			cost: 15,
			super: false,
			townHall: 11,
		},
		{
			name: 'Electro Dragon',
			id: 59,
			cost: 30,
			super: false,
			townHall: 11,
		},
		{
			name: 'Inferno Dragon',
			id: 63,
			cost: 15,
			super: true,
			townHall: 12,
		},
		{
			name: 'Super Valk',
			id: 64,
			cost: 20,
			super: true,
			townHall: 12,
		},
		{
			name: 'Dragon Rider',
			id: 65,
			cost: 25,
			super: false,
			townHall: 13,
		},
		{
			name: 'Super Witch',
			id: 66,
			cost: 40,
			super: true,
			townHall: 12,
		},
		{
			name: 'Ice Hound',
			id: 76,
			cost: 40,
			super: true,
			townHall: 12,
		},
		{
			name: 'Super Bowler',
			id: 80,
			cost: 30,
			super: true,
			townHall: 12,
		},
		{
			name: 'Super Dragon',
			id: 81,
			cost: 40,
			super: true,
			townHall: 12,
		},
		{
			name: 'Head Hunter',
			id: 82,
			cost: 6,
			super: false,
			townHall: 12,
		},
		{
			name: 'Super Wizard',
			id: 83,
			cost: 10,
			super: true,
			townHall: 12,
		},
		{
			name: 'Super Minion',
			id: 84,
			cost: 12,
			super: true,
			townHall: 12,
		},
		{
			name: 'Electro Titan',
			id: 95,
			cost: 32,
			super: false,
			townHall: 14,
		},
	]),

	spell: define('spell', [
		{
			name: 'Lightning',
			id: 0,
			cost: 1,
			townHall: 5,
		},
		{
			name: 'Heal',
			id: 1,
			cost: 2,
			townHall: 6,
		},
		{
			name: 'Rage',
			id: 2,
			cost: 2,
			townHall: 7,
		},
		{
			name: 'Jump',
			id: 3,
			cost: 2,
			townHall: 9,
		},
		{
			name: 'Freeze',
			id: 5,
			cost: 1,
			townHall: 9,
		},
		{
			name: 'Clone',
			id: 16,
			cost: 3,
			townHall: 10,
		},
		{
			name: 'Invisible',
			id: 35,
			cost: 1,
			townHall: 11,
		},
		{
			name: 'Recall',
			id: 53,
			cost: 2,
			townHall: 13,
		},
		{
			name: 'Poison',
			id: 9,
			cost: 1,
			townHall: 8,
		},
		{
			name: 'Earthquake',
			id: 10,
			cost: 1,
			townHall: 8,
		},
		{
			name: 'Haste',
			id: 11,
			cost: 1,
			townHall: 9,
		},
		{
			name: 'Skeletons',
			id: 17,
			cost: 1,
			townHall: 9,
		},
		{
			name: 'Bats',
			id: 28,
			cost: 1,
			townHall: 10,
		},
	]),

	siege: define('siege', [
		{
			name: 'Wall Wrecker',
			id: 51,
			townHall: 9,
			cost: 1,
		},
		{
			name: 'Battle Blimp',
			id: 52,
			townHall: 9,
			cost: 1,
		},
		{
			name: 'Stone Slammer',
			id: 62,
			townHall: 9,
			cost: 1,
		},
		{
			name: 'Siege Barracks',
			id: 75,
			townHall: 9,
			cost: 1,
		},
		{
			name: 'Log Launcher',
			id: 87,
			townHall: 9,
			cost: 1,
		},
		{
			name: 'Flame Flinger',
			id: 91,
			townHall: 9,
			cost: 1,
		},
		{
			name: 'Battle Drill',
			id: 92,
			townHall: 12,
			cost: 1,
		},
	]),
};

const map = <T extends Unit> (list: T[]) => Object.fromEntries(list.map(t => [t.id, t])) as Record<number, T>;
export const unitMap = {
	troop: map(unitList.troop),
	spell: map(unitList.spell),
	siege: map(unitList.siege),
};
